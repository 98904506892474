.feedback-container {
  padding: 2rem;
  font-family: Poppins;
  text-align: center;
}
.feedback-heading {
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;
  color: #2b2a2a;
  text-align: center;
}
.feedback-carousel-container {
  margin-top: 3rem;
  width: 100%;
}

.carousel-card {
  width: 450px;
  height: 450px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0.2, 0, 0, 0.2);
  border-radius: 1rem;
  padding: 2rem;
}

.feedback-text {
  margin-top: 34px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 38px;
  color: #585669;
  text-align: left;
  width: 100%;
}

.feedback-footer {
  display: flex;
  align-items: center;
}

.doctor-details {
  margin-left: 0.6rem;
}

.doctor-name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: #000000;
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: -0.6rem;
}

.doctor-specification {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 38px;
  color: #8c8c8c;
}
.carousel-container {
  padding: 1vh 0;
}

@media screen and (max-width: 600px) {
  .feedback-container {
    padding: 1rem 1rem;
    margin: 2rem -8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .feedback-heading {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 38px;
    color: #2b2a2a;
    text-align: center;
  }
  .carousel-container {
    padding: 1vh 0;
  }
  .feedback-carousel-container {
    margin-top: 2rem;
    width: 100%;
  }

  .carousel-card {
    width: 300px;
    height: 500px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    padding: 2rem;
  }

  .feedback-text {
    margin-top: 34px;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 38px;
    color: #585669;
    text-align: left;
  }

  .feedback-footer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 35px;
  }

  .doctor-details {
    margin-left: 0.6rem;
  }

  .doctor-name {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    color: #000000;
    text-align: left;
    margin-top: 0.5rem;
    margin-bottom: -0.6rem;
  }

  .doctor-specification {
    display: flex;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 38px;
    color: #8c8c8c;
  }
}
