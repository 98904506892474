.pricing-container {
  min-height: 20vh;
}

.first-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10%;
}
.enterprise-col {
  border-top: 2px solid blue;
  border-left: 2px solid blue;
  border-right: 2px solid blue;
}

.details-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid grey;
}

.feature {
  width: 80%;
}

.basic,
.enterprise {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.enterprise {
  border-left: 2px solid blue;
  border-right: 2px solid blue;
}

.enterprise-last {
  border-bottom: 2px solid blue;
}
