.logo:hover {
  transform: scale(1.25);
  background-color: #f3f3f3;
  padding: 0.5rem;
  border-radius: 18px;
}
.logo {
  transition: transform 0.4s;
}
.switch-button {
  background: #c1c1c1;
  border-radius: 30px;
  overflow: hidden;
  width: 240px;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  font-family: raleway;
  letter-spacing: 1px;
  color: #fff;
  position: relative;
  padding-right: 180px;
  position: relative;
}
.switch-button:before {
  content: "Businesses";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  color: #fff;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}
.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(180px);
  transition: transform 300ms linear;
}
.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 15px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}
.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: linear-gradient(122.11deg, #4d63dd 35.61%, #22a1f5 94.47%);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 300ms;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}
.counterSpan {
  background: linear-gradient(122.11deg, #4d63dd 35.61%, #22a1f5 94.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media screen and (max-width: 600px) {
  .logo:hover {
    transform: scale(1.05);
  }
  .switch-button {
    background: #c1c1c1;
    border-radius: 30px;
    overflow: hidden;
    width: 180px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    font-family: raleway;
    letter-spacing: 1px;
    color: #fff;
    position: relative;
    padding-right: 140px;
    position: relative;
  }
  .switch-button:before {
    content: "Business";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    color: #fff;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }
  .switch-button-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
  }
  .switch-button-checkbox:checked + .switch-button-label:before {
    transform: translateX(140px);
    transition: transform 300ms linear;
  }
}
