.container {
  height: 80px;
  display: flex;
}
.rotate {
  overflow: hidden;
}

.word {
  display: block;
  height: 100%;
  padding-left: 10px;
  color: #ae00ff;
  animation: rotate_words 6s infinite;
}

@keyframes rotate_words {
  10% {
    transform: translateY(-75%);
  }
  25% {
    padding-top: 2rem;

    transform: translateY(-79%);
  }
  35% {
    padding-top: 2rem;
    transform: translateY(-176%);
  }
  50% {
    padding-top: 2rem;
    transform: translateY(-180%);
  }
  60% {
    padding-top: 2rem;
    transform: translateY(-275%);
  }
  75% {
    padding-top: 2rem;
    transform: translateY(-280%);
  }
  85% {
    padding-top: 2rem;
    transform: translateY(-373%);
  }
  100% {
    padding-top: 2rem;
    transform: translateY(-380%);
  }
}
